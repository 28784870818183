<template>
  <div>
    <b-overlay
        :show="submittingFetch"
        rounded
        opacity="0.6"
        spinner-medium
        spinner-variant="primary"
        spinner-type="grow"
    >
      <div id="user-profile" v-if="Object.keys(user).length" >
        <profile-header
            :user="user"
        />

        <b-row>
          <b-col
              cols="12"
              lg="8"
              order="2"
              order-lg="1"
          >
            <b-card>

              <h5 class="text-capitalize mb-75">
                {{ $t('Offers') }}
              </h5>
              <b-card-text>
                <profile-service-offers
                    :user="user"
                />
              </b-card-text>
            </b-card>
          </b-col>
          <b-col
              cols="12"
              lg="4"
              order="1"
              order-lg="2"
          >
            <b-row class="match-height">
              <b-col>
                <b-card class="text-center text-secondary" v-if="user.bio">
                    {{ user.bio }}
                </b-card>
                <b-card>
                  <h5 class="text-capitalize mb-75">
                    {{ $t('Contact') }}
                  </h5>
                  <b-card-text>
                    <profile-phones
                        :user="user"
                    />
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <request-direct :user-data="user" v-if="user.id!==userAuthorized"/>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {
   BCard, BOverlay, BAlert, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import RequestDirect from '@/views/apps/service/request/RequestDirect.vue'
import { ref } from '@vue/composition-api'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import ProfileServiceOffers from '@/views/pages/profile/ProfileServiceOffers.vue'
import ProfilePhones from '@/views/pages/profile/ProfilePhones.vue'
import ProfileHeader from './ProfileHeader.vue'
import { getUserData } from '@/auth/utils'
export default {
  components: {
    BCard,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BCardText,
    RequestDirect,
    ProfileHeader,
    ProfilePhones,
    ProfileServiceOffers,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Service Provider Profile',
    // all titles will be injected into this template
    titleTemplate: '%s | OneTimeJob',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      {
        property: 'og:title',
        content: 'Online service for the management of short-term and one-time jobs',
        template: chunk => `${chunk} | OneTimeJob`,
        vmid: 'og:title',
      },
    ],
  },
  data() {
    return {

    }
  },
  setup() {
    const userAuthorized = getUserData().id
    const user = ref({})
    const submittingFetch = ref(false)
    const ability = defineAbilityForCurrentUser()

    // Remote Data
    const fetchUser = () => {
      submittingFetch.value = true
      // Get point  id from URL
      const { route } = useRouter()
      const userId = route.value.params.id

      let url = 'user/fetchServiceProviderPublic'
      if (userAuthorized) {
        url = 'user/fetchServiceProvider'
      }
      store.dispatch(url, { id: userId })
        .then(response => {
          user.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            user.value = undefined
          }
        }).finally(() => {
          submittingFetch.value = false
        })
    }

    const updateUserData = data => {
      if (data.receiver) {
        store.commit('user/updateUser', data.receiver)
      }
    }

    fetchUser()

    return {
      user,
      ability,
      submittingFetch,
      updateUserData,
      userAuthorized,
    }
  },

}

</script>
<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
