<template>
  <div v-if="!restricted">
    <b-overlay
      :show="fetchingLocation"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
      spinner-type="grow"
    >
      <validation-observer
        v-if="!fetchingLocation"
        ref="simpleRules"
      >
        <b-form
          ref="offer"
          @submit.prevent="submit"
        >
          <b-row>
            <b-col>
              <b-card
                no-body
              >
                <b-row
                  class="text-right actions match-height"
                >
                  <b-col>
                    <b-button
                      v-if="canBeDeleted()"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      @click="deleteLocation()"
                    >
                      <span
                        v-if="deletingLocation"
                        class="align-middle"
                      >
                        <b-spinner
                          type="grow"
                          small
                        />
                        {{ $t('Loading...') }}
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                        />{{ $t('location.delete') }}</span>

                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                      :disabled="!location.address_confirmed || dataSaved()"
                    >
                      <span
                        v-if="updatingLocation"
                        class="align-middle"
                      >
                        <b-spinner
                          type="grow"
                          small
                        />
                        {{ $t('Loading...') }}
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        <feather-icon
                          icon="SaveIcon"
                          class="mr-50"
                        />{{ $t('Submit') }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col></b-row>
          <b-row class="match-height">
            <b-col>
              <location-edit-details
                ref="map"
                :data="$store.state.location.location"
                @update-location="val=>updateLocation(val)"
              />
            </b-col>

          </b-row>

        </b-form>

      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>

import { ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import { showToast } from '@/mixins/notification/toasts'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import LocationEditDetails from '@/views/apps/location/edit/LocationEditDetails.vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { useServiceRequestsRemoteData } from '@/views/apps/service/request/list/useServiceRequestsList'

export default {
  components: {
    LocationEditDetails,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BOverlay,
    // OfferMedia,
    BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  data() {
    return {
      location: {},
      error404: false,
      restricted: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === 'apps-location-edit'
          && to.params.id
          && Number.isInteger(parseInt(to.params.id, 10))) {
        this.updateLocationData(to.params.id)
      }
      if (to.name === 'apps-location-add') {
        this.initLocation()
      }
    },
    deep: true,
    immediate: true,
  },

  mounted() {
    if (router.currentRoute.name === 'apps-location-edit'
        && router.currentRoute.params.id
        && Number.isInteger(parseInt(router.currentRoute.params.id, 10))) {
      this.updateLocationData(router.currentRoute.params.id)
    }
  },
  methods: {
    updateLocation(location) {
      this.location = location
    },

    canBeDeleted() {
      return store.state.location.location.id && router.currentRoute.name === ('apps-location-edit' || 'apps-location-add')
    },

    deleteLocation() {
      this.deletingLocation = true
      const html = ref('')
      const args = {
        address_ids: store.state.location.location.id,
      }

      this.fetchServiceRequests(args)
        .then(response => {
          const { total } = response.data.meta
          if (total) {
            const requests = response.data.data.map(el => el.name)
            html.value = this.$t('location.related_requests_will_be_lost')
              .concat(':<br><h4>')
              .concat(requests.join('<br>'))
              .concat('<h4>')
          }
          this.$swal({
            title: this.$t('Are you sure?'),
            html: html.value,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.$t('Yes'),
            cancelButtonText: this.$t('Cancel'),
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-outline-primary ml-1',
            },
            showClass: {
              popup: 'animate__animated animate__fadeIn',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              store.dispatch('location/deleteLocation', { id: store.state.location.location.id }).then(response => {
                if ([200, 201, 'success'].includes(response.status)) {
                  this.locationData = this.initLocationData.value
                  this.savedLocationData.value = JSON.stringify(this.locationData)
                  router.push({ name: 'apps-location-list' })
                }
                this.showToast(response, 'location.location')
              }).finally(() => { this.deletingLocation = false })
            }
            this.deletingLocation = false
          })
        })
    },

    updateLocationData(locationId) {
      this.fetchingLocation = true
      store.dispatch('location/fetchLocation', { id: locationId })
        .then(response => {
          if (response.status === 404) {
            this.error404 = true
            this.showAlert404()
          } else {
            const ability = defineAbilityForCurrentUser()
            if (ability.can('update', store.getters['location/Location'])) {
              this.savedLocationData.value = JSON.stringify(store.state.location.location)
              this.restricted = false
            } else {
              this.restricted = true
              router.push({ name: 'misc-not-authorized' })
            }
          }
          this.fetchingLocation = false
        })
    },
    showAlert404() {
      this.$swal({
        title: this.$t('location.not_found'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('location.back_to_locations_list'),
        cancelButtonText: this.$t('location.add'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary m-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          router.push({ name: 'apps-location-list' })
        } else {
          router.push({ name: 'apps-location-add' })
        }
      })
    },
    showAlert(next) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You didn't save the location"),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          next()
        }
      })
    },
    dataSaved() {
      const currentData = JSON.stringify(store.state.location.location, (key, val) => {
        if (typeof val === 'function') {
          if (key === 'name') {
            return val() // make it a string, surround it by parenthesis to ensure we can revive it as an anonymous function
          }
        }
        return val
      })

      return JSON.stringify(this.savedLocationData.value) === JSON.stringify(currentData)
    },

    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.save()
        } else {
          const el = document.querySelector('.text-danger')
          if (el) {
            el.scrollIntoView({ block: 'end' })
          }
        }
      })
    },
    initLocation() {
      store.commit('location/setLocation', this.initLocationData)
      this.savedLocationData = JSON.stringify(this.locationData)
    },
    requestData() {
      const { locationData } = this
      locationData.lat = this.location.position.lat
      locationData.lng = this.location.position.lng
      // locationData.location_radius = this.location.radius / 1000
      delete locationData.id
      delete locationData.address
      delete locationData.user
      delete locationData.user_id
      delete locationData.point_type
      delete locationData.point_purpose_ids

      // delete locationData.point_purposes
      return locationData
    },
    requestAddress() {
      return {
        country: this.location.address.country,
        country_code: this.location.address.country_code,
        postcode: this.location.address.postcode,
        state: this.location.address.state,
        locality: this.location.address.locality,
        road: this.location.address.road,
        house_number: this.location.address.house_number,
        apartment_number: this.location.address.apartment_number,
        address_confirmed: this.location.address_confirmed,
        // viewport: this.location.viewport,
      }
    },
    save() {
      this.updatingLocation = true

      if (router.currentRoute.name === 'apps-location-edit'
          && router.currentRoute.params.id
          && Number.isInteger(parseInt(router.currentRoute.params.id, 10))) {
        const payload = { data: this.requestData(), address: this.requestAddress(), id: router.currentRoute.params.id }

        store.dispatch('location/updateLocation', payload)
          .then(response => {
            this.savedLocationData.value = JSON.stringify(store.state.location.location)
            router.go(-1)
            this.showToast(response, 'location.location')
            this.$emit('location-updated', store.state.location.location)
          })
          .finally(() => { this.updatingLocation = false })
      } else {
        const payload = { data: this.requestData(), address: this.requestAddress() }

        store.dispatch('location/addLocation', payload)
          .then(response => {
            if ([200, 201, 'success'].includes(response.status)) {
              this.savedLocationData.value = JSON.stringify(store.state.location.location)
              this.$emit('location-updated', store.state.location.location)
            }
            this.showToast(response, 'location.location')
          })
          .finally(() => { this.updatingLocation = false })
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.dataSaved && !this.error404 && !this.restricted) {
      this.showAlert(next)
    } else {
      next()
    }
  },
  setup() {
    // const POINT_APP_STORE_MODULE_NAME = 'point'
    //
    // // Register module
    // if (!store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.registerModule(POINT_APP_STORE_MODULE_NAME, pointStoreModule)
    //
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.unregisterModule(POINT_APP_STORE_MODULE_NAME)
    // })

    const fetchingLocation = ref(false)
    const deletingLocation = ref(false)
    const updatingLocation = ref(false)

    const {
      fetchServiceRequests,
    } = useServiceRequestsRemoteData()

    const initLocationData = {
      id: '',
      user_id: '',
      lng: '',
      lat: '',
      address: {
        country: '',
        country_code: '',
        state: '',
        locality: '',
        locality_type: '',
        road: '',
        house_number: '',
        apartment_number: '',
        postcode: '',
        address_confirmed: false,
      },
      public_alias: '',
    }
    const locationData = ref(JSON.parse(JSON.stringify(initLocationData)))
    const savedLocationData = ref(JSON.parse(JSON.stringify(initLocationData)))
    return {
      locationData,
      initLocationData,
      savedLocationData,
      fetchServiceRequests,
      fetchingLocation,
      deletingLocation,
      updatingLocation,
    }
  },
}
</script>
