<template>
  <div>
    <b-overlay
      :show="fetchingLocations"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
      spinner-type="grow"
    >
      <b-modal
        id="add-location-modal"
        ref="add-location-modal"
        v-model="displayModalAddLocation"
        centered
        hide-footer
        size="xl"
      >
        <edit-location @location-updated="(val)=>locationUpdatedEvent(val)" />
      </b-modal>
      <b-card
        v-if="!locationId && editable"
        id="openMapModal"
        no-body
      >

        <b-card-header>
          <b-card-title>
            <div class="d-flex">
              <feather-icon
                icon="MapPinIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                {{ $t('location.my_locations') }}
              </h4>
            </div>
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              class="my-1 "
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="text-uppercase"
                block
                @click="addLocation()"
              >
                {{ $t('location.add') }}
              </b-button>
            </b-col>
            <b-col
              v-if="optionsLocations.length"
              cols="12"
            >
              <span>
                {{ $tc("location.locations_found", optionsLocations.length) }}
              </span>
              <v-select
                id="locations"
                ref="locations"
                v-model="$store.state.location.location"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('Address')"
                :filterable="true"
                :loading="fetchingLocations"
                :options="optionsLocations"
                :get-option-label="location => location.item_data"
                :clearable="false"
                :selected="true"
              >

                <template #option="{ item_data }">
                  <div class="text-wrap">
                    <strong> {{ item_data }}</strong>
                  </div>

                </template>
                <template #selected-option="{ item_data }">
                  <div class="text-wrap">
                    {{ item_data }}
                  </div>
                </template>

              </v-select>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <location-edit-details
        v-if="$store.state.location.location"
        ref="map"
        :data="$store.state.location.location"
        :editable="Object.keys($store.state.location.location).length === 0 && editable"
        :viewport="viewport"
        @update-location="updateLocationEvent()"
      />
    </b-overlay>
  </div>

</template>

<script>
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BButton,
  BOverlay,

} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useRemoteData } from '@/views/apps/location/list/useLocationsList'
import LocationEditDetails from '@/views/apps/location/edit/LocationEditDetails.vue'
import { serviceOptions } from '@/mixins/options'
import { getFormattedAddress } from '@/utils/location/location'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import ability from '@/libs/acl/ability'
import EditLocation from '@/views/apps/location/edit/LocationEdit.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BButton,
    BOverlay,
    EditLocation,
    vSelect,
    LocationEditDetails,
  },
  directives: {
    Ripple,
  },

  props: {
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
    viewport: {
      type: Array,
      required: false,
      default: () => [],
    },
    editable: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data() {
    return {

    }
  },
  mounted() {
    if (router.currentRoute.name === 'apps-service-request-add-wizard' && router.currentRoute.query.locationId) {
      this.setDirectLocation(router.currentRoute.query.locationId)
    } else if (ability.can('update', 'Account')) {
      this.fetchFilteredLocations()
    }
  },
  setup(props, { emit }) {
    const locationId = ref('')
    const displayModalAddLocation = ref(false)
    const ability = defineAbilityForCurrentUser()
    const optionsLocations = ref([])
    const fetchingLocations = ref(false)
    const { getPurposeTitle, getTypeTitle, getTranslatedPointPurposes } = serviceOptions
    const {
      fetchLocations, fetchPublicLocation, submittingFetch,
    } = useRemoteData()

    async function setDirectLocation(locationAlias) {
      return fetchPublicLocation(locationAlias)
        .then(response => {
          const { data } = response.data
          locationId.value = data.id
          if (response.status === 404) {
            // this.showAlert404()
          }
        })
    }

    const addLocation = () => {
      displayModalAddLocation.value = true
      store.commit('location/setLocation', {})
    }

    const setOptionsLocations = async (args, data) => {
      const optionsList = data

      optionsList.map(el => {
        el.item_data = getFormattedAddress(el.address) || ''
        return el
      })

      optionsLocations.value = optionsList
      return optionsLocations.value
    }

    async function fetchFilteredLocations() {
      fetchingLocations.value = true
      const args = {
        // q: filters.value.q,
        sort_by: 'created_at',
        order: 'desc',
        per_page: 200,
      }

      return new Promise((resolve, reject) => {
        fetchLocations(args)
          .then(async response => {
            await setOptionsLocations(args, response.data.data)
            store.commit('location/setLocation', optionsLocations.value ? optionsLocations.value[0] : {})
            return resolve(true)
          })
          .catch(error => reject(error)).finally(() => {
            fetchingLocations.value = false
          })
      })
    }

    const updateLocationEvent = () => {
      emit('update-location', store.state.location.location)
    }
    const locationUpdatedEvent = val => {
      displayModalAddLocation.value = false
      fetchFilteredLocations()
    }

    return {
      displayModalAddLocation,
      optionsLocations,
      fetchingLocations,
      getPurposeTitle,
      getTypeTitle,
      getTranslatedPointPurposes,
      addLocation,
      submittingFetch,
      setDirectLocation,
      updateLocationEvent,
      locationUpdatedEvent,
      fetchFilteredLocations,
      ability,
      locationId,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
<style scoped lang="scss">
.vs--disabled{
  color: #00cfe8 !important;
}

</style>
