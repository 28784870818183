var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"authForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",attrs:{"id":"register"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('b-form-group',{attrs:{"label-for":"name","label":_vm.$t('auth.Name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.Name'),"rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"name",attrs:{"id":"name","state":errors.length > 0 ? false:null},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('auth.Phone'),"label-for":"phone"}},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.Phone'),"vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"id":"phone","error":_vm.phone.valid!==true,"name":"phone","valid-color":"#28C76F","required":true,"translations":{
              countrySelectorLabel: _vm.$t('Country code'),
              countrySelectorError: _vm.$t('Choose a country'),
              phoneNumberLabel: _vm.$t('Phone Number'),
              example: _vm.$t('Example:')
            },"only-countries":_vm.countriesList,"default-country-code":_vm.countryCode},on:{"update":_vm.onPhoneUpdate},model:{value:(_vm.phone.number),callback:function ($$v) {_vm.$set(_vm.phone, "number", $$v)},expression:"phone.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.phone.valid)?_c('div',[_c('small',[_vm._v(_vm._s(_vm.$t('auth.We might ask you to enter a verification code that we send to this phone' )))])]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"password","label":_vm.$t('auth.Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Password'),"rules":"min:8|required|confirmed:@confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"placeholder":"········"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.password)?_c('b-form-group',{attrs:{"label-for":"password-confirmation","label":_vm.$t('auth.Password Confirmation')}},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.Password Confirmation'),"rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password-confirmation","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"placeholder":"········"},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('re-captcha',{attrs:{"humanoid-verifying-required":_vm.humanoidVerifyingRequired},on:{"humanoid-verified":function (recaptchaToken){ return _vm.humanoidVerified(recaptchaToken); }}}),_c('otp-form',{attrs:{"phone":_vm.getFormattedPhone(),"verifying-required":_vm.verifyingRequired,"new-user-phone":_vm.newUserPhone},on:{"phoneVerified":function($event){return _vm.onPhoneVerified()},"verifyingRequired":function (val){ return _vm.onVerifyingRequired(val); }}}),(!_vm.verifyingRequired)?_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('auth.privacy policy & terms'),"rules":"agree"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-checkbox',{ref:"privacyPolicy",attrs:{"id":"privacy-policy","name":_vm.$t('auth.privacy policy & terms')},model:{value:(_vm.privacyPolicyAgree),callback:function ($$v) {_vm.privacyPolicyAgree=$$v},expression:"privacyPolicyAgree"}},[_vm._v(" "+_vm._s(_vm.$t('auth.I agree to'))+" "),_c('b-link',{attrs:{"to":{path:("/" + (_vm.$i18n.locale) + "/privacy-policy") }}},[_vm._v(" "+_vm._s(_vm.$t('auth.privacy policy & terms'))+" ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(!_vm.verifyingRequired && !_vm.registerButtonHidden)?_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid||_vm.submitting}},[(_vm.submitting)?_c('span',[_c('b-spinner',{attrs:{"type":"grow","small":""}}),_vm._v(" "+_vm._s(_vm.$t('auth.Registration'))+" ")],1):_vm._e(),(!_vm.submitting)?_c('span',[_vm._v(" "+_vm._s(_vm.getRegisterButtonText())+" ")]):_vm._e()]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }