<template>
  <div>

    <!-- form -->
    <validation-observer
      ref="authForm"
      #default="{invalid}"
    >
      <b-form
        id="register"
        class="auth-register-form mt-2"
        @submit.prevent="submit"
      >
        <!-- name -->
        <b-form-group
          label-for="name"
          :label="$t('auth.Name')"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('auth.Name')"
            rules="required|min:4"
          >
            <b-form-input
              id="name"
              ref="name"
              v-model="userName"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- email -->
        <!--              <b-form-group-->
        <!--                label="Email"-->
        <!--                label-for="register-email"-->
        <!--              >-->
        <!--                <validation-provider-->
        <!--                  #default="{ errors }"-->
        <!--                  name="Email"-->
        <!--                  vid="email"-->
        <!--                  rules="required|email"-->
        <!--                >-->
        <!--                  <b-form-input-->
        <!--                    id="register-email"-->
        <!--                    v-model="userEmail"-->
        <!--                    name="register-email"-->
        <!--                    :state="errors.length > 0 ? false:null"-->
        <!--                    placeholder="john@example.com"-->
        <!--                  />-->
        <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
        <!--                </validation-provider>-->
        <!--              </b-form-group>-->

        <!--phone-->
        <b-form-group
          :label="$t('auth.Phone')"
          label-for="phone"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('auth.Phone')"
            vid="phone"
            rules="required"
          >
            <VuePhoneNumberInput
              id="phone"
              v-model="phone.number"
              :error="phone.valid!==true"
              name="phone"
              valid-color="#28C76F"
              :required="true"
              :translations="{
                countrySelectorLabel: $t('Country code'),
                countrySelectorError: $t('Choose a country'),
                phoneNumberLabel: $t('Phone Number'),
                example: $t('Example:')
              }"
              :only-countries="countriesList"
              :default-country-code="countryCode"
              @update="onPhoneUpdate"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <div v-if="phone.valid">
              <small>{{ $t('auth.We might ask you to enter a verification code that we send to this phone' ) }}</small>
            </div>

          </validation-provider>
        </b-form-group>

        <!-- password -->
        <b-form-group
          label-for="password"
          :label="$t('auth.Password')"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Password')"
            rules="min:8|required|confirmed:@confirmation"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password"
                v-model="password"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false:null"
                placeholder="········"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password confirmation -->
        <b-form-group
          v-if="password"
          label-for="password-confirmation"
          :label="$t('auth.Password Confirmation')"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('auth.Password Confirmation')"
            rules="required"
            vid="confirmation"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password-confirmation"
                v-model="passwordConfirmation"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false:null"
                placeholder="········"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--ReCaptcha-->
        <re-captcha
          :humanoid-verifying-required="humanoidVerifyingRequired"
          @humanoid-verified="(recaptchaToken)=>humanoidVerified(recaptchaToken)"
        />
        <!--OTP-->
        <otp-form
          :phone="getFormattedPhone()"
          :verifying-required="verifyingRequired"
          :new-user-phone="newUserPhone"
          @phoneVerified="onPhoneVerified()"
          @verifyingRequired="(val)=>onVerifyingRequired(val)"
        />

        <!-- policy -->
        <b-form-group v-if="!verifyingRequired">
          <validation-provider
            #default="{ errors }"
            :name="$t('auth.privacy policy & terms')"
            rules="agree"
          >
            <b-form-checkbox
              id="privacy-policy"
              ref="privacyPolicy"
              v-model="privacyPolicyAgree"
              :name="$t('auth.privacy policy & terms')"
            >
              {{ $t('auth.I agree to') }}
              <b-link :to="{path:`/${$i18n.locale}/privacy-policy` }">
                {{ $t('auth.privacy policy & terms') }}
              </b-link>
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          v-if="!verifyingRequired && !registerButtonHidden"
          variant="primary"
          block
          type="submit"
          :disabled="invalid||submitting"
        >
          <span v-if="submitting">
            <b-spinner
              type="grow"
              small />
            {{ $t('auth.Registration') }}
          </span>
          <span v-if="!submitting">
            {{ getRegisterButtonText() }}
          </span>

        </b-button>

      </b-form>
    </validation-observer>

    <!-- divider -->
    <!--          <div class="divider my-2">-->
    <!--            <div class="divider-text">-->
    <!--              or-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
    <!--            <b-button-->
    <!--              variant="facebook"-->
    <!--              href="javascript:void(0)"-->
    <!--            >-->
    <!--              <feather-icon icon="FacebookIcon" />-->
    <!--            </b-button>-->
    <!--            <b-button-->
    <!--              variant="twitter"-->
    <!--              href="javascript:void(0)"-->
    <!--            >-->
    <!--              <feather-icon icon="TwitterIcon" />-->
    <!--            </b-button>-->
    <!--            <b-button-->
    <!--              variant="google"-->
    <!--              href="javascript:void(0)"-->
    <!--            >-->
    <!--              <feather-icon icon="MailIcon" />-->
    <!--            </b-button>-->
    <!--            <b-button-->
    <!--              variant="github"-->
    <!--              href="javascript:void(0)"-->
    <!--            >-->
    <!--              <feather-icon icon="GithubIcon" />-->
    <!--            </b-button>-->
    <!--          </div>-->

    <!-- /Register-->

  </div>
</template>

<script>

import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate'
import {
  required, email, min, digits,
} from '@validations'
import {
  BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showToast } from '@/mixins/notification/toasts'
import { numbersOnly, translateApiErrors } from '@/mixins/functions'
import i18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { $themeConfig } from '@themeConfig'
import { ref } from '@vue/composition-api'

import store from '@/store'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { pushNotification } from '@/views/apps/notification/useNotification'
import { useReferral } from '@/views/apps/referral/useReferral'
import { currency, locales } from '@/mixins/options'
import otpForm from './OtpForm.vue'
import reCaptcha from './ReCaptcha.vue'

extend('agree', {
  validate(value) {
    return value
  },
  message: i18n.t('auth.agreement_with_the_terms_of_use_of_the_resource_is_mandatory'),
})

extend('confirmed', {
  params: ['target'],
  validate(value, { target }) {
    return target ? value === target : true
  },

  message: i18n.t('auth.password_confirmation_does_not_match'),
})

export default {
  components: {

    BLink,
    BButton,
    BForm,
    // Cleave,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,

    VuePhoneNumberInput,
    // sidebar,
    otpForm,
    reCaptcha,

  },
  mixins: [togglePasswordVisibility, showToast, numbersOnly],

  props: {

    registerButtonHidden: {
      type: Boolean,
      default: () => false,
    },
    registerButtonText: {
      type: String,
      required: false,
      default: '',
    },
    redirectUser: {
      type: Boolean,
      required: false,
      default: true,
    },
    attemptToAuthorize: {
      type: Number,
      required: false,
      default: 0,
    },

  },
  data() {
    return {
      phone: {
        number: '',
        valid: false,
        country: undefined,
      },
      privacyPolicyAgree: false,
      submitting: false,
      verifyingRequired: false,
      humanoidVerifyingRequired: false,
      userName: '',
      userEmail: '',
      userPhone: null,
      password: '',
      passwordConfirmation: '',
      newUserPhone: '',
      // validation
      required,
      email,
      min,
      digits,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    countryCode() {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode || 'UA'
    },

  },
  watch: {
    // eslint-disable-next-line func-names
    attemptToAuthorize(val) {
      if (val) {
        this.submit()
      }
    },
  },

  created() {
    this.$nextTick(() => this.$refs.name.focus())
  },
  methods: {
    autoLogIn(response) {
      useJwt.setToken(response.data.access_token)
      useJwt.setRefreshToken(response.data.refresh_token)
      store.dispatch('account/getUserCurrent')
        .then(currentUser => {
          const userData = currentUser.data
          if (this.redirectUser) {
            this.$router.replace(getHomeRouteForLoggedInUser())
              .then(() => {
                this.showWelcomeMessage(userData)
              })
          }
          this.$emit('user-logged-in', userData)
          this.createFcmSubscription()
        })
        .catch(error => {
          this.errorMessage = this.$t(error.response.message)
          if (error.response.data.error === 'registration_unverified') {
            // Display OTP form
            // this.verifyingRequired = true
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.submitting = false
          }, 1000)
        })
    },
    showWelcomeMessage(userData) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `${this.$t('auth.Welcome')}, ${userData.name}`,
          icon: 'CoffeeIcon',
          variant: 'success',
          text: `${this.$t('auth.Now you can start to add or search requests for one time job!')}`,
        },
      })
    },
    getRegisterButtonText() {
      return this.registerButtonText || this.$t('auth.Sign up')
    },
    onPhoneVerified() {
      this.redirectToLoginPage()
    },
    onVerifyingRequired(val) {
      this.verifyingRequired = val
    },
    // redirectToLoginPage() {
    //   const customSuccessText = 'You have successfully registered! Please Sign In'
    //   const title = `${this.$t('auth.Welcome')}, ${this.userName}`
    //   this.$router.push('/login')
    //   this.showToast({ status: 200 }, 'Account', title, customSuccessText, 10000)
    // },
    onPhoneUpdate({
      countryCode, isValid, phoneNumber, countryCallingCode, formattedNumber,
    }) {
      this.phone.valid = isValid
      if (isValid) {
        this.userPhone = formattedNumber
      }
    },
    getFormattedPhone() {
      return this.userPhone ? this.userPhone.replace(/\+/g, '') : ''
    },
    humanoidVerified(recaptchaToken) {
      this.humanoidVerifyingRequired = false
      this.register(recaptchaToken)
    },
    register(recaptchaToken) {
      this.submitting = true
      useJwt.register({
        name: this.userName,
        // email: this.userEmail,
        phone: this.getFormattedPhone(),
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        lang: i18n.locale,
        referral_code: this.referralCode,
        currency: this.getCurrency(),
        recaptchaToken,
      })
        .then(response => {
          if (response.status === 200) {
            // Display OTP form
            // this.newUserPhone = this.getFormattedPhone()
            // Redirect to login page
            // this.redirectToLoginPage()
            this.autoLogIn(response)
          }
        })
        .catch(error => {
          this.$emit('registration-error')
          const { errors } = error.response.data
          translateApiErrors(errors)
          this.$refs.authForm.setErrors(
            error.response.data.errors,
          )
        })
        .finally(() => {
          setTimeout(() => {
            this.submitting = false
          }, 1000)
        })
    },
    submit() {
      this.$refs.authForm.validate().then(success => {
        if (success) {
          this.humanoidVerifyingRequired = true
        }
      })
    },
  },
  setup() {
    const isEventHandlerSidebarActive = ref(false)
    const { appName } = $themeConfig.app
    const { createFcmSubscription } = pushNotification()
    const { getRefInvitation } = useReferral()
    const referralCode = getRefInvitation()
    const { getCurrency } = currency()
    const {  countriesList } = locales()
    return {
      appName,
      isEventHandlerSidebarActive,
      createFcmSubscription,
      referralCode,
      getCurrency,
      countriesList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.vue-phone-number-input .select-country-container {
  min-width: 90px!important;
  max-width: 90px!important;
}
.country-selector__input {
  padding-right: 0!important;
}
</style>
<style scoped>
.otp{
  max-width: 120px;
}
.custom-control {
   z-index: 0;
}
</style>
