<template>
  <b-card
      id="openMapModal"
      no-body
  >
    <b-card-header>
      <b-card-title>
        <div class="d-flex">
          <feather-icon
              icon="MapPinIcon"
              size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ $t('Address') }}
          </h4>
        </div>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>

        <b-col
            v-if="!modalMapShow"
            cols="12"
            class="text-center"
        >
          <b-button
              variant="primary"
              class="m-1"
              @click="indicateLocation"
          >
            <span
                v-if="loading"
                class="align-middle"
            >
              <b-spinner
                type="grow"
                small  />
            </span>

            <span
                v-else
                class="align-middle"
            >
              <feather-icon
                v-if="!loading"
                icon="MapPinIcon"
                class="mr-25"
            />
              {{ $t('Indicate on the map') }}
            </span>

          </b-button>
        </b-col>
        <b-col cols="12">
          <b-modal
              id="permission-modal"
              ref="permission-modal"
              v-model="modalPermissionShow"
              centered
              hide-footer
              :static="true"
          >
            <b-alert
                variant="primary"
                show
            >
              <h4 class="alert-heading">
                {{ $t('Please provide geolocation permission') }}
              </h4>
              <div class="alert-body">
                <p>{{ $t('Permission has been blocked as you ignore the permission prompt several times or denied permission') }}</p>
                <p>{{ $t('This can be reset in Page Info which can be accessed by clicking the lock icon next to the URL') }}</p>
                <p>
                  {{ $t('See for more information:') }}
                  <b-link
                      href="https://support.google.com/chrome/answer/142065?hl=en"
                      target="_blank"
                      class="alert-link"
                  > https://support.google.com/chrome/answer/142065?hl=en</b-link>
                </p>
              </div>
            </b-alert>
            <!--/ alert -->
            <div class="text-center">
              <b-button
                  variant="primary"
                  class="m-1"
                  @click="showMapModal()"
              >
                <feather-icon
                    icon="MapIcon"
                    class="mr-25"
                />
                <span
                    class="align-middle"
                >
                  {{ $t('Indicate on the map') }}
                </span>

              </b-button>
            </div>
          </b-modal>
          <div v-show="modalMapShow">
            <network-status @connection-restored="refreshMap" />
            <b-alert
                v-if="alertMessage"
                show
                variant="danger"
            >
              <div class="alert-body">
                <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                />
                <span class="ml-25">{{ alertMessage }}</span>
              </div>
            </b-alert>

            <l-map
                ref="map"
                :center="center"
                :options="map.options"
                style="height: 240px; width: 100%"
                @dblclick="onMapClick"
            >
              <l-tile-layer :url="tileProvider.url" />
              <!--              <l-geosearch :options="geoSearchOptions" />-->
              <l-marker
                  v-if="location.position.lat && location.position.lng"
                  visible
                  :draggable="editable"
                  :lat-lng.sync="location.position"
                  @dragstart="dragging = true"
                  @dragend="coordinatesAreChanged()"
              />

              <l-circle
                  v-if="location.radius"
                  ref="circle"
                  :lat-lng.sync="location.position"
                  :radius="location.radius"
                  color="red"
              />

              <l-circle
                  v-if="location.viewportRadius"
                  :lat-lng.sync="location.viewportPosition"
                  :radius="location.viewportRadius"
                  fill-color="#ff9f43"
                  :fill-opacity="0.09"
                  :weight="0"
              />
            </l-map>
            <h5 class="my-1">{{ location.formatted_address }}</h5>
          </div>
          <b-card-text v-if="modalMapShow">

            <b-form>
              <!--                <b-form-group-->
              <!--                  :label="$t('Address')"-->
              <!--                  label-for="address"-->
              <!--                >-->
              <!--                  <b-form-input-->
              <!--                    id="address"-->
              <!--                    ref="autocomplete"-->
              <!--                    v-model="location.formatted_address"-->
              <!--                    disabled-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <b-row>
                <b-col

                    md="12"
                    lg="6"
                    xl="4"
                >
                  <b-form-group
                      :label="$t('Country')"
                      label-for="country"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Country')"
                        rules="required"
                    >
                      <b-form-input
                          id="country"
                          v-model="location.address.country"
                          v-capitalize-first-letter
                          trim
                          :readonly="!editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                    md="12"
                    lg="6"
                    xl="4"
                >
                  <b-form-group
                      :label="$t('State')"
                      label-for="state"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('State')"
                        rules="required"
                    >
                      <b-form-input
                          id="state"
                          v-model="location.address.state"
                          v-capitalize-first-letter
                          trim
                          :readonly="!editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                    md="12"
                    xl="4"
                >

                  <b-form-group
                      :label="$t('Locality')"
                      label-for="locality"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Locality')"
                        rules="required"
                    >
                      <b-form-input
                          id="locality"
                          v-model="location.address.locality"
                          v-capitalize-first-letter
                          trim
                          :readonly="!editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row>
                <b-col
                    cols="12"
                    xl="6"
                    md="12"
                >
                  <b-form-group
                      :label="$t('Street')"
                      label-for="road"
                  >
                    <b-form-input
                        id="road"
                        v-model="location.address.road"
                        trim
                        :readonly="!editable"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6"
                    xl="2"
                >
                  <b-form-group
                      :label="$t('House')"
                      label-for="house_number"
                  >
                    <b-form-input
                        id="house_number"
                        v-model="location.address.house_number"
                        :disabled="!location.address.road"
                        trim
                        :readonly="!editable"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6"
                    xl="2"
                >
                  <b-form-group
                      :label="$t('Apt.')"
                      label-for="apartment_number"
                  >
                    <b-form-input
                        id="apartment_number"
                        v-model="location.address.apartment_number"
                        :disabled="!location.address.house_number"
                        trim
                        :readonly="!editable"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6"
                    xl="2"
                >
                  <b-form-group
                      :label="$t('Postcode')"
                      label-for="postcode"
                  >
                    <b-form-input
                        id="postcode"
                        v-model="location.address.postcode"
                        trim
                        :readonly="!editable"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    class="text-left"
                >
                  <b-alert
                      v-if="alertMessage"
                      show
                      variant="danger"
                  >
                    <div class="alert-body">
                      <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                      />
                      <span class="ml-25">{{ alertMessage }}</span>
                    </div>
                  </b-alert>
                  <b-alert
                      variant="danger"
                      :show="!location.address_confirmed"
                  >
                    <div class="alert-body">
                      <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                      />
                      {{ $t('Address is unconfirmed') }}
                    </div>
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Address')"
                        rules="required"
                    >
                      <b-form-input
                          :v-model="location.address_confirmed"
                          :disabled="true"
                          class="d-none"
                      />
                      <!--                        <small class="text-danger">{{ errors[0] }}</small>-->
                    </validation-provider>
                  </b-alert>
                  <b-alert
                      variant="success"
                      :show="location.address_confirmed"
                  >
                    <div class="alert-body">
                      <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                      />
                      {{ $t('Address is confirmed') }}

                    </div>
                  </b-alert>
                  <b-alert
                      variant="danger"
                      :show="!coordinatesTaken"
                  >
                    <div class="alert-body">
                      <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                      />
                      {{ $t('Coordinates have not been updated') }}
                    </div>
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Coordinates')"
                        rules="required"
                    >
                      <b-form-input
                          :v-model="coordinatesTaken"
                          :disabled="true"
                          class="d-none"
                      />
                      <!--                        <small class="text-danger">{{ errors[0] }}</small>-->
                    </validation-provider>
                  </b-alert>
                  <b-alert
                      variant="success"
                      :show="coordinatesTaken"
                  >
                    <div class="alert-body">
                      <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                      />

                      {{ $t('Coordinates have been updated') }}
                    </div>
                  </b-alert>
                  <span
                      v-if="error"
                      class="text-danger"
                  >{{ error }}</span>
                </b-col>
                <b-col
                    class="text-right"
                    sm="12"
                    xl="6"
                >
                  <b-button
                      v-if="addressChanged && !coordinatesTaken"
                      variant="primary"
                      block
                      :disabled="loading"
                      @click="fetchLocationBySearchQuery(location, true)"
                  >
                    <b-spinner
                        type="grow"
                        v-if="loading"
                        small
                    />
                    {{ $t('Update Address') }}
                  </b-button>
                  <!--                    <b-button-->
                  <!--                      v-if="coordinatesTaken && !location.address_confirmed"-->
                  <!--                      variant="primary"-->
                  <!--                      block-->
                  <!--                      @click="confirmAddress('customer')"-->
                  <!--                    >-->
                  <!--                      {{ $t('Confirm Address') }}-->
                  <!--                    </b-button>-->
                </b-col>
              </b-row>
            </b-form>

          </b-card-text>

        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardText,
  BButton,
  BAlert,
  BSpinner,
  BLink,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'
import {
  required, confirmed,
} from '@validations'
import {
  LMap, LTileLayer, LMarker, LCircle,
} from 'vue2-leaflet'
import { nextTick, ref } from '@vue/composition-api'
// import { heightFade } from '@core/directives/animations'
import { capitalizeFirstLetter } from '@/directives/capitalize'
import {
  Icon, latLngBounds,
} from 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import LGeosearch from 'vue2-leaflet-geosearch'

import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import store from '@/store/index'

import * as locationService from '@/utils/location/location'
/* eslint-disable global-require */
import { getFormattedAddress } from '@/mixins/functions'

import networkStatus from '@/layouts/components/NetworkStatus.vue'
import router from '@/router'
import { useUserLocation } from '@/views/apps/user/useUser'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BButton,
    BAlert,
    BSpinner,
    BLink,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LGeosearch,
    ValidationProvider,
    networkStatus,

  },
  directives: {
    // 'height-fade': heightFade,
    'capitalize-first-letter': capitalizeFirstLetter,
  },
  mixins: [getFormattedAddress],
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 49.444444,
        lng: 32.059722,
      }),
    },
    viewport: {
      type: Array,
      required: false,
      default: () => [],
    },
    editable:{
      type: Boolean,
      required: false,
      default: () => true,
    }
  },
  data() {
    return {
      modalMapShow: false,
      modalPermissionShow: false,
      addressForm: false,
      required,
      confirmed,
      point: this.data,
      fetchedAddress: {},
      dragging: false,
      loading: false,
      error: null,
      coordinatesTaken: false,
      addressChanged: false,
      fetchedLocation: null,
      typoInForm: false,
      location:JSON.parse(JSON.stringify(this.locationInit)),
      alertMessage: '',
      map: {
        options: {
          zoomControl: true,
          attributionControl: false,
          zoomSnap: true,
          zoom: 15,
          minZoom: 6,
          maxZoom: 30,
          scrollWheelZoom: false,
        },
      },
      tileProvider: {
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      geoSearchOptions: {
        provider: new OpenStreetMapProvider({
          params: {
            'accept-language': this.userCountryCode, // render results in language
            // countrycodes: [this.$i18n.locale], // limit search results for country
          },
        }),
        autoClose: true,
        retainZoomLevel: false,
        animateZoom: true,
      },
      tooltip: {
        options: {
          permanent: true,
          direction: 'top',
          interactive: true,
          noWrap: true,
          opacity: 0.9,
        },
      },
    }
  },
  computed: {
    serviceRadius() {
      let km = 0
      let m = 0
      const { radius } = this.location
      if (radius) {
        km = Math.floor(radius / 1000)
        m = radius % 1000
      }
      return (m) ? `${km} km : ${m} m` : `${km} km`
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
    center() {
      return [
        this.location.position.lat || this.defaultLocation.lat,
        this.location.position.lng || this.defaultLocation.lng,
      ]
    },

  },
  watch: {
    viewport: {
      handler(val) {
        if (val.length) {
          this.updateLocationViewport()
          this.checkIfLocationInServiceArea()
        }
      },
    },
    'location.position': {
      handler() {
        this.point.lng = this.location.position.lng
        this.point.lat = this.location.position.lat
        this.checkIfLocationInServiceArea()
      },
    },
    'location.radius': {
      deep: true,
      async handler() {
        if (!this.location.radius)return
        await this.$nextTick(() => {
          const circle = this.$refs.circle.mapObject
          this.$refs.map.mapObject.fitBounds(circle.getBounds())
          this.location.viewport = circle.getBounds()
        })
      },
    },

  },
  // created() {
  // if (navigator.geolocation) { // check if geolocation is available
  //   navigator.geolocation.getCurrentPosition(position => {
  //
  //   })
  // }

  // },
  mounted() {
    // this.$watch('error', newValue => {
    //   console.log(newValue)
    //   if (newValue) {
    //     this.$swal({
    //       title: this.$t('message.warning'),
    //       text: this.$t(newValue.message),
    //       icon: 'warning',
    //       showDenyButton: true,
    //       showCancelButton: true,
    //       showConfirmButton: this.typoInForm,
    //       confirmButtonText: this.$t('Yes'),
    //       cancelButtonText: this.$t('Cancel'),
    //       denyButtonText: this.$t('Indicate on the map'),
    //       customClass: {
    //         confirmButton: 'btn btn-success mr-1 mb-1',
    //         cancelButton: 'btn btn-outline-primary ml-1 mb-1',
    //         denyButton: 'btn btn-primary mb-1',
    //       },
    //       showClass: {
    //         popup: 'animate__animated animate__fadeIn',
    //       },
    //       buttonsStyling: false,
    //     }).then(result => {
    //       if (result.isConfirmed) {
    //         this.updateLocation()
    //       } else if (result.isDenied) {
    //         this.showMapModal()
    //       }
    //     })
    //   }
    // })
    // if (router.currentRoute.name === 'apps-service-request-add' || router.currentRoute.name === 'apps-service-request-add-wizard') {
    //   this.fetchCurrentLocation()
    // }
    this.$watch('data', newValue => {
      if (newValue.id) {
        this.point = newValue
        this.showMapModal()
        this.setPosition(this.point.lat, this.point.lng)
        this.setRadius()
        this.setAddress()
        this.location.address_confirmed = this.point.address.address_confirmed
      } else {
        this.modalMapShow = false
        this.location = JSON.parse(JSON.stringify(this.locationInit))
      }

    }, {immediate:true})

    this.$watch('location.address', newValue => {
      this.location.formatted_address = this.getFormattedAddress(newValue)
      if (this.addressChanged) {
        this.error = false
      }
      this.addressForm = true
    }, { deep: 'true' })
    // this.$watch('location.address_confirmed', newValue => {
    //   if (newValue) {
    //     this.$emit('updateLocation', this.location)
    //   }
    // })
    this.$watch('location.address.country', () => {
      this.updateAddressConfirmation()
    })
    this.$watch('location.address.state', () => {
      this.updateAddressConfirmation()
    })
    this.$watch('location.address.locality', () => {
      this.updateAddressConfirmation()
    })
    this.$watch('location.address.road', newValue => {
      if (newValue === '') {
        this.location.address.house_number = ''
      }
      this.updateAddressConfirmation()
    })
    this.$watch('location.address.house_number', newValue => {
      if (newValue === '') {
        this.location.address.apartment_number = ''
      }
      this.updateAddressConfirmation()
    })
    // this.$watch('location.address.apartment_number', () => {
    //   if (JSON.stringify(this.location.address) !== this.fetchedAddress) {
    //     this.coordinatesTaken = true
    //     this.unConfirmAddress()
    //   } else {
    //     this.coordinatesTaken = true
    //     this.confirmAddress()
    //   }
    // })
    this.$refs.map.mapObject.on('geosearch/showlocation', this.onSearch)
  },
  methods: {

    async indicateLocation() {
      const locationTaken = await this.fetchCurrentLocation()
      if (locationTaken) {
        this.showMapModal()
      }
    },
    updateLocationViewport() {
      this.viewportBounds = latLngBounds(this.viewport)
      const center = this.viewportBounds.getCenter()
      const mapBoundNorthEast = this.viewportBounds.getNorthEast()
      const mapDistance = mapBoundNorthEast.distanceTo(center)
      this.location.viewportRadius = mapDistance / 1.26
      this.location.viewportPosition = { lat: center.lat, lng: center.lng }
    },

    checkIfLocationInServiceArea() {
      if (this.viewportBounds && this.point.lat && this.point.lng) {
        this.alertMessage = !this.viewportBounds.contains({ lat: this.location.position.lat, lng: this.location.position.lng }) ? this.$t('errors.location.outside_the_service_area') : ''
        return false
      }
      return true
    },

    refreshMap() {
      return true
    },
    toggleAddressForm() {
      this.addressForm = !this.addressForm
    },
    showMapModal() {
      // this.$refs['map-modal'].show()
      this.modalMapShow = true
      this.modalPermissionShow = false
      // nextTick()
      this.rerenderMap()
    },
    rerenderMap() {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    updateAddressConfirmation() {
      this.addressChanged = JSON.stringify(this.location.address) !== this.fetchedAddress
      if (this.addressChanged) {
        this.coordinatesTaken = false
        this.unConfirmAddress()
      } else {
        this.coordinatesTaken = true
        this.location.address_confirmed = true
      }
    },
    locationIsValid(location) {
      if (typeof location === 'undefined' || location.length === 0) {
        this.setError(this.$t('errors.location.can_not_find_place_coordinates'))
        return false
      }
      if (this.location.address.house_number !== '' && (this.location.address.house_number !== location.address.house_number)) {
        this.setError(this.$t('errors.location.can_not_find_house_coordinates'))
        return false
      }
      if (this.location.address.locality && this.location.address.locality !== this.transformFetchedLocality(location) && !this.error) {
        this.setError(`${this.$t('errors.location.can_not_find_address_coordinates', { currentLocality: this.location.address.locality, possibleLocality: this.transformFetchedLocality(location) })}?`)
        this.typoInForm = true
        return false
      }

      if (this.location.address.road !== '' && !location.address.road.includes(this.location.address.road) && !this.error) {
        this.setError(`${this.$t('errors.location.wrong_street_name', { streetName: location.address.road })}?`)
        this.typoInForm = true
        return false
      }
      this.error = false
      return true
    },
    transformFetchedLocality(location) {
      let locality = null
      if ('city' in location.address) {
        locality = location.address.city
      }
      if ('town' in location.address) {
        locality = location.address.town
      }
      if ('village' in location.address) {
        locality = location.address.village
      }
      return locality
    },
    confirmAddress() {
      this.location.address_confirmed = true
    },
    unConfirmAddress() {
      this.location.address_confirmed = false
    },
    async coordinatesAreChanged() {
      await this.fetchLocationByCoordinates(this.location.position.lat, this.location.position.lng)
    },
    updateLocation(location) {
      const loc = location || this.fetchedLocation
      this.setLocation(loc)
      this.setPosition(loc.lat, loc.lon)

      this.confirmAddress()
    },
    setLocation(location) {
      this.location.address = { ...this.location.address, ...location.address }
      this.location.address.locality = this.transformFetchedLocality(location)
      this.fetchedAddress = JSON.stringify(this.location.address)
    },
    setPosition(lat, lng) {
      this.location.position = { lat, lng }
      localStorage.setItem('lat', lat)
      localStorage.setItem('lng', lng)
      this.coordinatesTaken = true
      this.$emit('update-location', this.location)
    },
    setRadius() {
      this.location.radius = this.point.location_radius * 1000
    },
    setAddress() {
      this.location.address = this.point.address
      this.fetchedAddress = JSON.stringify(this.point.address)
    },
    async fetchCurrentLocation() {
      try {
        this.setLoadingState()
        const location = await locationService.currentLocation(this.userCountryCode)
        if (location) {
          this.setLocation(location)
          this.setPosition(location.lat, location.lon)
          this.coordinatesTaken = true
          this.confirmAddress()
          return true
        }
      } catch (error) {
        if (error.code === 1) {
          this.modalPermissionShow = true
        }
      } finally {
        this.loading = false
      }

      return false
    },
    async fetchLocationBySearchQuery(query, structured = false) {
      try {
        this.setLoadingState()
        await locationService.locationBySearchQuery(query.address, this.userCountryCode, structured).then(location => {
          const loc = location[0]
          this.fetchedLocation = loc
          if (this.locationIsValid(loc)) {
            this.updateLocation(loc)
          } else {
            this.unConfirmAddress()
          }
          this.loading = false
        })
      } catch (error) {
        this.setError(error)
      }
    },
    async fetchLocationByCoordinates(lat, lng) {
      try {
        this.setLoadingState()
        await locationService.locationByCoordinates({ latitude: lat, longitude: lng }, this.userCountryCode).then(location => {
          if (location) {
            this.setLocation(location)
            this.setPosition(lat, lng)
            this.coordinatesTaken = true
            this.confirmAddress()
          }
          this.loading = false
        })
      } catch (error) {
        this.setError(error)
      }
    },
    setError(error) {
      this.error = error
      this.loading = false
    },
    setLoadingState() {
      this.error = null
      this.loading = true
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      await this.fetchLocationByCoordinates(value.latlng.lat, value.latlng.lng)
    },
    onSearch(value) {
      const { location } = value
      this.setPosition(location.y, location.x)
      this.fetchLocationByCoordinates(location.y, location.x)
    },
  },
  setup(){
    const locationInit = {
      address: {
        country: '',
        country_code: '',
        postcode: '',
        locality: '',
        road: '',
        state: '',
        house_number: '',
        apartment_number: '',
      },
      formatted_address: '',
      address_confirmed: false,
      position: {
        lat: null,
        lng: null,
      },
      radius: 0,
      viewport: {
        _southWest: {
          lat: 0,
          lng: 0,
        },
        _northEast: {
          lat: 0,
          lng: 0,
        },
      },
      viewportRadius: 0,
      viewportPosition: {
        lat: null,
        lng: null,
      },
      viewportBounds: {}
    }

    const { userCountryCode } = useUserLocation()
    return{
      userCountryCode,
      locationInit,
    }
  }

}
</script>
<style>
.leaflet-top, .leaflet-bottom {
  z-index: auto!important;
}
.leaflet-container{
  z-index: 0;
}
#map-modal .alert,
#map-modal .form-control{
  border-radius: 0;
  margin-bottom: 0!important;
}
#openMapModal .alert:not(#map-modal .alert){
  margin-bottom: 0.5rem!important;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
